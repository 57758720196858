import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  setHolidayListData: [], // Default to an empty array
};

export const holidayListSlice = createSlice({
  name: "holidayList",
  initialState,
  reducers: {
    setHolidayListData: (state, action) => {
      state.setHolidayListData = action.payload;
    },
  },
});

export const { setHolidayListData } = holidayListSlice.actions;

export default holidayListSlice.reducer;
