import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { login } from "service/authService";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn, expired } = useSelector(
    (state) => state.auth.session
  );

  const signIn = async (data) => {
    try {
      const resp = await login(data);
      if (resp?.jwt) {
        const token = resp?.jwt;
        console.log("resp", resp);
        dispatch(onSignInSuccess(token));

        dispatch(
          setUser({
            id: resp?.user?._id ? resp?.user?._id : "null",
            username: resp?.user?.username ? resp?.user?.username : "user",
            name: resp?.user?.name ? resp?.user?.name : "user",
            email: resp?.user?.email ? resp?.user?.email : "",
            phoneNumber: resp?.user?.phoneNumber ? resp?.user?.phoneNumber : "",
            birthDate: resp?.user?.birthDate ? resp?.user?.birthDate : "",
            inferiorUser: resp?.user?.user_role?.inferiorUser
              ? resp?.user?.user_role?.inferiorUser
              : false,
            ctc: resp?.user?.ctc ? resp?.user?.ctc : 0,
            profilePhoto: resp?.user?.profilePhoto
              ? resp?.user?.profilePhoto
              : "",

            authority: resp?.user?.user_role.role
              ? resp?.user?.user_role.role.split(" ")
              : ["employee"],
            permissions: resp?.user?.user_role?.assignedPermission
              ? resp?.user?.user_role?.assignedPermission
              : [],
          })
        );

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        window.location.reload();
        return {
          status: "success",
          message: "",
        };
      }
    } catch (err) {
      console.log(err);
      return {
        status: "failed",
        message: err?.response?.data?.error?.message || err.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
    localStorage.clear();
    window.location.reload();
  };

  return {
    authenticated: token && signedIn && expired > new Date().getTime(),
    signIn,
    signOut,
  };
}

export default useAuth;
